.blog-container {
  display: flex;
  flex-direction: column;
  max-width: 1270px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.blog-header {
  text-align: left;
  margin: 10px 20px;
}

.blog-header h1 {
  font-size: 2.5rem;
  color: #333;
}

.blog-main {
  display: flex;
  width: 1162px;
  gap: 20px;
}

.blog-article {
  flex: 3;
  line-height: 1.6;
}

.blog-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-intro,
.blog-body,
.blog-conclusion {
  margin-bottom: 20px;
}

.blog-intro p,
.blog-body p,
.blog-conclusion p {
  margin-bottom: 15px;
}

.blog-body ul,
.blog-body ol {
  margin-bottom: 15px;
}

.blog-sidebar {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.blog-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.blog-sidebar ul {
  list-style: none;
  padding: 0;
}

.blog-sidebar li {
  margin-bottom: 10px;
}

.blog-sidebar a {
  text-decoration: none;
  color: #007bff;
  font-size: 1rem;
}

.blog-sidebar a:hover {
  text-decoration: underline;
}

/* Responsive Styles */

@media (max-width: 768px) {
  .blog-header h1 {
    font-size: 2rem;
  }

  .blog-main {
    flex-direction: column;
    width: auto;
  }

  .blog-sidebar {
    flex-basis: auto;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .blog-header h1 {
    font-size: 1.5rem;
  }

  .blog-sidebar {
    padding: 10px;
  }

  .blog-sidebar h2 {
    font-size: 1.2rem;
  }

  .blog-sidebar a {
    font-size: 0.9rem;
  }
}

.next-btn {
  background-color: rgb(247, 191, 86);
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.next-btn:hover {
  background-color: #0056b3;
  color: #f4f4f4;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.onion-link {
  color: black !important;
  font-weight: bold;
}

/* Styling for the blog intro container */
.blog-intro {
  max-width: 1200px;
  margin: 50px auto; /* Center the content */
  padding: 20px;
  background-color: #fff; /* White background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners for modern look */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

/* Add hover effect for the container */
.blog-intro:hover {
  transform: translateY(-10px); /* Slight lift on hover */
}

/* Styling for the heading */
h2.section-title {
  color: #ff7e28; /* Blue color for heading */
  font-size: 1.5rem; /* Large, modern font size */
  margin-bottom: 10px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px; /* Spaced-out text for modern feel */
}

/* Styling for the paragraph */
p.section-title {
  font-style: italic;
  font-size: 1rem; /* Modern font size */
  line-height: 1.6; /* Good line spacing for readability */
  margin: 0;
  padding: 0;
  text-align: center;
}

/* Add some subtle animation */
.blog-intro {
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.sub-heading {
  color: #000;
}
.section-content {
  text-align: center;
}
.blog-main {
  display: flex;
  width: 1162px;
  gap: 20px;
  align-items: flex-start; /* Align items to the top */
}

.blog-article {
  flex: 3 1;
  line-height: 1.6;
}

.blog-sidebar {
  flex: 1 1;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  position: sticky;
  top: 20px; /* Adjust this value based on your layout */
  align-self: flex-start; /* Ensure the sidebar aligns to the top */
}
.blog-container {
  display: flex;
  flex-direction: column;
  max-width: 1270px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.blog-header {
  text-align: left;
  margin: 10px 20px;
}

.blog-header h1 {
  font-size: 2.5rem;
  color: #333;
}

.blog-main {
  display: flex;
  width: 100%;
  max-width: 1162px;
  gap: 20px;
  align-items: flex-start; /* Aligns items to the top */
}

.blog-article {
  flex: 3;
  line-height: 1.6;
}

.blog-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-intro,
.blog-body,
.blog-conclusion {
  margin-bottom: 20px;
}

.blog-intro p,
.blog-body p,
.blog-conclusion p {
  margin-bottom: 15px;
}

.blog-body ul,
.blog-body ol {
  margin-bottom: 15px;
}

.blog-sidebar {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  position: sticky;
  top: 20px; /* Keeps sidebar fixed while scrolling */
  align-self: flex-start; /* Ensures it aligns to the top */
}

.blog-sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.blog-sidebar ul {
  list-style: none;
  padding: 0;
}

.blog-sidebar li {
  margin-bottom: 10px;
}

.blog-sidebar a {
  text-decoration: none;
  color: #007bff;
  font-size: 1rem;
}

.blog-sidebar a:hover {
  text-decoration: underline;
}

/* Responsive Styles */

/* Tablets (max-width: 1024px) */
@media (max-width: 1024px) {
  .blog-header h1 {
    font-size: 2.2rem;
  }

  .blog-main {
    flex-direction: column;
    width: 100%;
  }

  .blog-sidebar {
    flex-basis: auto;
    margin-top: 20px;
    position: static; /* Remove sticky positioning for smaller screens */
  }
}

/* Mobile devices (max-width: 768px) */
@media (max-width: 768px) {
  .blog-header h1 {
    font-size: 2rem;
  }

  .blog-main {
    flex-direction: column;
    width: 100%;
  }

  .blog-sidebar {
    padding: 15px;
  }

  .blog-sidebar h2 {
    font-size: 1.3rem;
  }

  .blog-sidebar a {
    font-size: 0.95rem;
  }

  .next-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Small mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .blog-header h1 {
    font-size: 1.5rem;
  }

  .blog-container {
    padding: 10px;
  }

  .blog-intro {
    padding: 15px;
  }

  .blog-sidebar {
    padding: 10px;
  }

  .blog-sidebar h2 {
    font-size: 1.2rem;
  }

  .blog-sidebar a {
    font-size: 0.9rem;
  }

  .next-btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* Button Styling */
.next-btn {
  background-color: rgb(247, 191, 86);
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.next-btn:hover {
  background-color: #0056b3;
  color: #f4f4f4;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.onion-link {
  color: black !important;
  font-weight: bold;
}

/* Styling for the blog intro container */
.blog-intro {
  max-width: 1200px;
  margin: 50px auto; /* Center the content */
  padding: 20px;
  background-color: #fff; /* White background for contrast */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners for modern look */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

/* Add hover effect for the container */
.blog-intro:hover {
  transform: translateY(-10px); /* Slight lift on hover */
}

/* Styling for the heading */
h2.section-title {
  color: #ff7e28; /* Blue color for heading */
  font-size: 1.5rem; /* Large, modern font size */
  margin-bottom: 10px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px; /* Spaced-out text for modern feel */
}

/* Styling for the paragraph */
p.section-title {
  font-style: italic;
  font-size: 1rem; /* Modern font size */
  line-height: 1.6; /* Good line spacing for readability */
  margin: 0;
  padding: 0;
  text-align: center;
}

/* Add some subtle animation */
.blog-intro {
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sub-heading {
  color: #000;
}

.section-content {
  text-align: center;
}
